<template>
  <div>
    <b-row>
      <b-col
        md="7"
        class="mb-[24px]"
      >
        <section class="scroll_event">
          <div class="text-[#333333] text-lg mb-2">
            Header Formulir
            <span class="text-red-500">*</span>
          </div>
          <section class="mb-[24px]">
            <div class="preview_image mb-2">
              <section :class="state.previewImage ? 'show_image' : ''">
                <span
                  v-if="!state.previewImage"
                  class="before_preview"
                >
                  <img src="https://storage.googleapis.com/komerce/assets/illustration/Support-sending-images-&-text.svg">
                </span>
                <img
                  v-if="state.previewImage"
                  :src="state.previewImage"
                  class="w-100 image_preview"
                  alt="Preview Image"
                  style="border-radius: 8px;"
                >
              </section>
            </div>
            <b-input-group>
              <b-input-group-append
                class="d-flex align-items-center w-100"
                @click="$refs.photoFile.click()"
              >
                <BButton
                  class="d-flex align-items-center ml-[4px] w-40 btn-upload"
                  size="sm"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-upload.svg"
                    alt=""
                    width="15px"
                  >
                  <span
                    class="ml-[2px] text-[#F95031]"
                    style="font-size: 12px"
                  >
                    Pilih Foto</span>
                </BButton>
                <b-form-input
                  v-model="state.photoName"
                  class="text-file-foto"
                  style="border-left: none;
                    border-bottom-left-radius: 0%;
                    border-top-left-radius: 0%;
                    border: none;
                    background-color: white;"
                  :readonly="true"
                  :placeholder="state.photoName? state.photoName : 'upload Foto' "
                />
              </b-input-group-append>
              <input
                id="poster"
                ref="photoFile"
                class="form-control"
                type="file"
                style="display: none"
                @change="validateExt"
              >
            </b-input-group>
            <div
              :class="error.form.poster?'text-primary': ''"
              class="text-xs mt-[4px]"
            >
              *Upload foto dengan maksimal 1 Mb ukuran 1200x360px
            </div>
          </section>
          <section>
            <b-form-group
              id="textarea-rows"
              label-class="text-md text-[#828282]"
              label-for="textarea-rows"
            >
              <template #label>
                <span class="text-lg text-[#333333]">Closing Statement</span>
                <span class="text-red-500">*</span>
              </template>
              <div>
                <b-form-textarea
                  id="textarea-rows"
                  v-model="state.form.description"
                  placeholder="Masukan closing statement"
                  rows="8"
                  :maxlength="700"
                  :class="error.form.description?'border-primary': ''"
                  no-resize
                />
                <span :class="error.form.description ? 'text-xs flex justify-content-between' : 'text-xs flex justify-content-end'">
                  <span
                    v-if="error.form.description"
                    class="text-xs  text-primary"
                  >
                    {{ error.form.description }}
                  </span>
                  <span>
                    {{ state.form.description.length }}/700
                  </span>
                </span>
              </div>
            </b-form-group>
          </section>
          <section>
            <b-form-group
              id="fieldset-1"
              label-class="text-md text-[#828282]"
              label-for="linkUrl"
            >
              <template #label>
                <span class="text-lg text-[#333333]">URL Setting</span>
                <span class="text-red-500">*</span>
              </template>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text style="background-color: #F4F4F4; color:#626262">
                    https://komclass.id/form-
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="price"
                  v-model="state.form.linkUrl"
                  type="text"
                  :maxlength="50"
                  placeholder="Masukkan URL event"
                  :class="error.form.linkUrl?'border-primary': ''"
                  @input="replaceSpaceWithDash"
                />
              </b-input-group>
              <span
                v-if="error.form.linkUrl"
                class="text-xs  text-primary"
              >
                {{ error.form.linkUrl }}
              </span>
            </b-form-group>
          </section>
          <section>
            <div>
              <div>
                <span class="text-lg text-[#333333]">Question</span>
                <span class="text-red-500">*</span>
              </div>
              <b-tabs content-class="mt-2">
                <b-tab
                  title="Standar"
                  active
                >
                  <section class="section_datadiri mb-[24px]">
                    <div class="mb-[24px]">
                      <div class="text-lg text-[#333333] mb-[20px]">
                        Data Diri
                      </div>
                      <b-row>
                        <b-col
                          v-for="(item, index) in dataPersonal"
                          :key="index"
                          md="6"
                          class="mb-[24px]"
                          @click="onDragEnd(item)"
                        >
                          <div :class="item.isDrop === true ? 'content_datadiri_off' : 'content_datadiri'">
                            <img
                              :src="imagePersonal[index]"
                              alt="icon"
                            >
                            <span class="text_content_datadiri">{{ item.label }}</span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <div class="text-lg text-[#333333] mb-[20px]">
                        Data Bisnis
                      </div>
                      <b-row>
                        <b-col
                          v-for="(item, index) in dataBisnis"
                          :key="index"
                          md="6"
                          class="mb-[24px]"
                          @click="onDragEnd(item)"
                        >
                          <div :class="item.isDrop === true ? 'content_datadiri_off' : 'content_datadiri'">
                            <img
                              :src="imageBisnis[index]"
                              alt="icon"
                            >
                            <span class="text_content_datadiri">{{ item.label }}</span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </section>
                </b-tab>
                <b-tab title="Custom">
                  <section class="section_datadiri mb-[24px]">
                    <b-row>
                      <b-col
                        v-for="(item, index) in dataCustom"
                        :key="index"
                        md="6"
                        class="mb-[24px]"
                        @click="onDragEndCustom(item)"
                      >
                        <div class="content_datadiri">
                          <img
                            :src="imageCustom[index]"
                            width="40px"
                            alt="icon"
                          >
                          <span class="text_content_datadiri">{{ item.label }}</span>
                        </div>
                      </b-col>
                    </b-row>
                  </section>
                </b-tab>
              </b-tabs>
            </div>
          </section>
        </section>
      </b-col>
      <b-col
        md="5"
        class="mb-[24px]"
      >
        <div class="text-[#333333] text-lg mb-2">
          Layout Formulir
        </div>
        <div class="preview_image mb-2">
          <section :class="state.previewImage ? 'show_image' : ''">
            <span
              v-if="!state.previewImage"
              class="before_preview"
            >
              <img src="https://storage.googleapis.com/komerce/assets/illustration/Support-sending-images-&-text.svg">
            </span>
            <img
              v-if="state.previewImage"
              :src="state.previewImage"
              class="w-100 image_preview"
              alt="Preview Image"
              style="border-radius: 8px;"
            >
          </section>
        </div>
        <section>
          <div class="preview_content">
            <div
              v-if="viewContent.length === 0"
              class="no_content"
            >
              <span>Geser elemen ke sini untuk mulai buat form!</span>
            </div>
            <div class="view_content mb-[24px]">
              <draggable
                v-model="viewContent"
                tag="div"
                item-key="id"
                group="personal"
                :move="onMoveView"
              >
                <section
                  v-for="item in viewContent"
                  :key="item.id"
                >
                  <div class="d-flex gap-[8px]">
                    <div class="icon_more cursor-move">
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
                        alt="icon"
                      >
                    </div>
                    <b-form-group
                      id="nama_peserta"
                      label-class="text-md text-[#828282]"
                      label-for="nama_peserta"
                      class="w-[100%]"
                    >
                      <template #label>
                        <span class="text-lg text-[#333333]">{{ item.label }}</span>
                      </template>
                      <b-form-input
                        id="nama_peserta"
                        type="text"
                        :maxlength="50"
                        :minlength="3"
                        :placeholder="item.placeholder"
                        disabled
                        style="background: transparent"
                      />
                    </b-form-group>
                    <div class="d-flex gap-[8px]">
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit-2.svg"
                        width="24px"
                        alt="icon"
                        :class="item.isEdit ? 'cursor-pointer' : 'disabled-icon'"
                        @click="item.isEdit ? editItem(item) : ''"
                      >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                        width="24px"
                        alt="icon"
                        style="cursor:pointer;"
                        @click="removeItem(item)"
                      >
                    </div>
                  </div>
                </section>
              </draggable>
            </div>
            <div class="button_register d-flex gap-[8px]">
              <b-button
                variant="primary"
                class="w-100"
              >
                Daftar Sekarang
              </b-button>
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit-2.svg"
                alt="icon"
                class="disabled-icon"
              >
            </div>
          </div>
        </section>
      </b-col>
    </b-row>
    <div class="divider_solid" />
    <b-row>
      <b-col>
        <div
          class="d-flex mt-2 md:mt-2 justify-end "
          style="gap: 1rem;"
        >
          <b-button
            variant="outline-primary"
            :disabled="state.isNext === true"
            @click="handleBack"
          >
            Kembali
          </b-button>
          <b-button
            :variant="state.isDisabled === true ? 'secondary' : 'primary'"
            :disabled="state.isDisabled === true || state.isLoading === true"
            @click="handleSubmitFormEvent"
          >
            Simpan
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-edit-custom"
        ref="modalRef"
        hide-footer
        hide-header
        :centered="true"
      >
        <div class="px-1 py-2">
          <div class="data-edit">
            <div class="label">
              <b-form-group
                label-class="text-md text-[#828282]"
                class="w-[100%]"
              >
                <template #label>
                  <span class="text-lg text-[#333333]">Label</span>
                </template>
                <b-form-input
                  v-model="state.updateData.label"
                  type="text"
                  :maxlength="50"
                  :minlength="3"
                  :placeholder="state.dataEdit.label"
                  @input="updateText"
                />
              </b-form-group>
            </div>
            <div class="placeholder">
              <b-form-group
                label-class="text-md text-[#828282]"
                class="w-[100%]"
              >
                <template #label>
                  <span class="text-lg text-[#333333]">Placeholder</span>
                </template>
                <b-form-input
                  v-model="state.updateData.placeholder"
                  type="text"
                  :maxlength="50"
                  :minlength="3"
                  :placeholder="state.dataEdit.placeholder"
                  @input="updatePlaceholder"
                />
              </b-form-group>
            </div>
            <div
              v-if="state.dataEdit.type === 'dropdown'"
              class="option-custom"
            >
              <p>Pilihan</p>
              <div class="dropdown-custom">
                <draggable
                  v-model="state.updateData.options"
                  tag="div"
                  item-key="index"
                  :move="onMoveView"
                >
                  <div
                    v-for="(group, index) in state.updateData.options"
                    :key="index"
                    class="w-[100%]"
                  >
                    <div class="d-flex gap-[8px]">
                      <div class="icon_more cursor-move">
                        <img
                          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
                          alt="icon"
                        >
                      </div>
                      <b-form-group
                        :id="'nama_peserta_' + index"
                        label-class="text-md text-[#828282]"
                        :label-for="'nama_peserta_' + index"
                        class="w-[100%]"
                      >
                        <b-form-input
                          :id="'nama_peserta_' + index"
                          v-model="state.updateData.options[index]"
                          type="text"
                          :maxlength="50"
                          :minlength="3"
                          :placeholder="'Pilihan ' + (index + 1)"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </draggable>
                <div style="text-align: end;">
                  <b-button
                    variant="primary"
                    @click="addInput"
                  >
                    +
                  </b-button>
                </div>
              </div>
            </div>
            <div
              v-if="state.dataEdit.type === 'number'"
              class="divider_solid"
            />
            <div
              v-if="state.dataEdit.type === 'number'"
              class="angka-custom"
            >
              <b-form-group
                label-class="text-md text-[#828282]"
                class="w-[100%]"
              >
                <template #label>
                  <span class="text-lg text-[#333333]">Nilai Minimal</span>
                </template>
                <b-form-input
                  v-model="state.updateData.min_value"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                label-class="text-md text-[#828282]"
                class="w-[100%]"
              >
                <template #label>
                  <span class="text-lg text-[#333333]">Nilai Maksimal</span>
                </template>
                <b-form-input
                  v-model="state.updateData.max_value"
                  type="number"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-checkbox
                id="checkbox-1"
                v-model="state.updateData.required"
                class="mt-[15px]"
                name="checkbox-1"
              >
                Required?
              </b-form-checkbox>
            </div>
          </div>
          <b-row class="mt-2">
            <b-col>
              <b-button
                block
                variant="outline-primary"
                @click="cancelEdit"
              >
                Batal
              </b-button>
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                block
                @click="saveEdit"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  reactive, ref, watchEffect, onMounted,
} from '@vue/composition-api'
import { newAxiosIns } from '@/libs/axios'
import router from '@/router'
import { isNumber } from '@/libs/helpers'
import draggable from 'vuedraggable'
import { toastSuccess, toastError } from '../config'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    draggable,
  },
  props: {
    data: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const error = reactive({
      form: {
        poster: '',
        description: '',
        linkUrl: '',
      },
    })
    const state = reactive({
      photoName: '',
      originalPoster: null,
      data: {},
      form: {
        poster: '',
        description: '',
        linkUrl: '',
      },
      previewImage: null,
      dataEdit: {
        label: '',
        placeholder: '',
        type: '',
      },
      dataQuestion: [],
      updateData: {
        label: '',
        placeholder: '',
        options: ['Pilihan 1'],
        min_value: null,
        max_value: null,
        required: false,
      },
      isLoading: false,
    })

    const photoUpload = ref({})
    const disabledButton = () => {
      if (state.form.description === '' || !state.photoName || state.form.linkUrl === '' || state.dataQuestion.length === 0 || state.isLoading === true) {
        state.isDisabled = true
      } else {
        state.isDisabled = false
      }
    }
    function handleSubmitFormEvent() {
      state.isLoading = true
      const urlEvent = `form-${state.form.linkUrl}`
      const questionData = state.dataQuestion.map(({
        id, isEdit, isDrop, ...rest
      }) => ({
        ...rest,
        event_id: props.data,
        min_value: Number(rest.min_value) || null,
        max_value: Number(rest.max_value) || null,
      }))
      const formData = new FormData()
      formData.append('header', photoUpload.value)
      formData.append('closing_statement', state.form.description)
      formData.append('url', urlEvent)
      formData.append('event_id', props.data)
      formData.append('questions', JSON.stringify(questionData))

      const url = '/komclass/api/v1/question'
      newAxiosIns.post(url, formData)
        .then(res => {
          router.push({
            name: 'data-event',
          })
          toastSuccess('Berhasil Menambahkan Field Form Registrasi')
          state.isLoading = false
        })
        .catch(err => {
          state.isLoading = false
          const { meta } = err.response.data
          if (meta.message === 'form url for this event already exist') {
            toastError('Link url telah digunakan')
          } else {
            toastError(meta.message)
          }
        })
    }

    function handleBack() {
      router.back()
    }

    const validateSize = evChange => {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]
      state.previewImage = URL.createObjectURL(file)

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size >= maxSize) {
          toastError('Ukuran file terlalu besar. Maksimal 1 MB.')
          photoUpload.value = ''
          state.photoName = ''
          state.previewImage = state.originalPoster
        } else {
          photoUpload.value = file
        }
      }
    }
    const validateExt = evChange => {
      const file = evChange.target.value
      if (!file) {
        return
      }
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
      ) {
        toastError('Hanya dapat upload file dengan ekstensi JPG dan PNG')
        state.photoName = ''
        state.previewImage = state.originalPoster
      } else if (evChange.target.files.length > 0) {
        state.photoName = evChange.target.files[0].name
        validateSize(evChange)
      }
    }

    const onMove = event => event.from !== event.to
    const onMoveView = event => {}
    const imagePersonal = [
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/nama.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/email.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/no_hp.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/asal_kota.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/kebutuhan_training.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/info_kegiatan.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/kategori_peserta.svg',
    ]
    const imageBisnis = [
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/bidang_usaha.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/jumlah_karyawan.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/volume_pengiriman.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/kendala.svg',
      'https://storage.googleapis.com/komerce/assets/LP-Komclass/social_media.svg',
    ]
    const imageCustom = [
      'https://storage.googleapis.com/komerce/icon/teks.svg',
      'https://storage.googleapis.com/komerce/icon/dropdown.svg',
      'https://storage.googleapis.com/komerce/icon/angka.svg',
      'https://storage.googleapis.com/komerce/icon/tanggal.svg',
    ]
    const dataPersonal = ref([
      {
        isDrop: false,
        label: 'Nama',
        placeholder: 'Masukkan nama lengkapmu',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'text',
        options: null,
      },
      {
        isDrop: false,
        label: 'Email',
        placeholder: 'Masukkan emailmu',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'text',
        options: null,
      },
      {
        isDrop: false,
        label: 'No. HP',
        placeholder: 'Masukkan nomor HP kamu',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'text',
        options: null,
      },
      {
        isDrop: false,
        label: 'Asal Kota',
        placeholder: 'Masukkan Kota atau Kabupaten',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'text',
        options: null,
      },
      {
        isDrop: false,
        label: 'Kebutuhan Training',
        placeholder: 'Pilih kebutuhan trainingmu',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'dropdown',
        options: [
          'Pribadi',
          'Perusahaan',
        ],
      },
      {
        isDrop: false,
        label: 'Info Kegiatan',
        placeholder: 'Dimana mendapatkan info kegiatan ini?',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'dropdown',
        options: [
          'Facebook',
          'TikTok',
          'Instagram',
          'Google',
          'Email Komerce',
          'Yang Lain',
        ],
      },
      {
        isDrop: false,
        label: 'Kategori Peserta',
        placeholder: 'Kategori peserta',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'personal',
        type: 'dropdown',
        options: [
          'Umum',
          'Pengguna Komship',
          'Pengguna Komtim',
          'Pengguna Kompack',
          'Pengguna Komplace',
          'Pengguna Komcards',
          'Pengguna Komads',
          'Pengguna Komchat',
          'Pengguna Pendamping UMKM by Komerce',
          'Yang Lain',
        ],
      },
    ])
    const dataBisnis = ref([
      {
        isDrop: false,
        label: 'Bidang Usaha',
        placeholder: 'Bidang usaha kamu saat ini',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'business',
        type: 'dropdown',
        options: [
          'Kuliner',
          'Fashion',
          'Beauty/Kecantikan',
          'Herbal/Obat-Obatan',
          'Agribisnis/Pertanian',
          'Otomotif',
          'Jasa',
          'Yang Lain',
        ],
      },
      {
        isDrop: false,
        label: 'Jumlah Karyawan',
        placeholder: 'Jumlah karyawan',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'business',
        type: 'dropdown',
        options: [
          '1-2 Karyawan',
          '4-8 Karyawan',
          '9-15 Karyawan',
          '>15 Karyawan',
          'Belum punya karyawan',
        ],
      },
      {
        isDrop: false,
        label: 'Volume Pengiriman',
        placeholder: 'Estimasi volume pengiriman dalam sebulan',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'business',
        type: 'dropdown',
        options: [
          '1-100 Pengiriman',
          '101-500 Pengiriman',
          '501-1000 Pengiriman',
          '1001-5000 Pengiriman',
          '5000 - 10.000 Pengiriman',
          '>10.000 Pengiriman',
        ],
      },
      {
        isDrop: false,
        label: 'Kendala yang Dialami',
        placeholder: 'Kendala yang dialami saat ini',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'business',
        type: 'text',
        options: null,
      },
      {
        isDrop: false,
        label: 'Sosial Media',
        placeholder: 'Username sosial media',
        event_id: 0,
        min_value: null,
        max_value: null,
        required: true,
        section: 'business',
        type: 'text',
        options: null,
      },
    ])
    const dataCustom = ref([
      {
        label: 'Text',
        placeholder: 'Ketik disini',
        isEdit: true,
        min_value: null,
        max_value: null,
        required: false,
        section: 'other',
        type: 'text',
        options: null,
      },
      {
        label: 'Dropdown',
        placeholder: 'Pilih opsi',
        isEdit: true,
        min_value: null,
        max_value: null,
        required: false,
        section: 'other',
        type: 'dropdown',
        options: null,
      },
      {
        label: 'Angka',
        placeholder: 'Masukkan angka',
        isEdit: true,
        min_value: null,
        max_value: null,
        required: false,
        section: 'other',
        type: 'number',
        options: null,
      },
      {
        label: 'Tanggal',
        placeholder: 'Pilih tanggal',
        isEdit: true,
        min_value: null,
        max_value: null,
        required: false,
        section: 'other',
        type: 'date',
        options: null,
      },
    ])
    const viewContent = ref([])
    const onDragEnd = val => {
      const item = val
      if (!viewContent.value.includes(item)) {
        viewContent.value.push(item)
        item.isDrop = true
      }
      state.dataQuestion = viewContent.value
    }
    const onDragEndCustom = val => {
      const item = val
      viewContent.value.push({ ...item, id: Math.random().toString(36).substr(2, 9) })
      state.dataQuestion = viewContent.value
    }
    const dragItem = item => {
      if (!viewContent.value.includes(item)) {
        viewContent.value.push(item)
      }
    }
    function removeItem(item) {
      const index = viewContent.value.indexOf(item)
      viewContent.value.splice(index, 1)
      const personalItem = dataPersonal.value.find(personal => personal.label === item.label)
      const bisnisItem = dataBisnis.value.find(bisnis => bisnis.label === item.label)
      if (personalItem) {
        personalItem.isDrop = false
      }
      if (bisnisItem) {
        bisnisItem.isDrop = false
      }
    }
    const replaceSpaceWithDash = val => {
      state.form.linkUrl = val.replace(/\s+/g, '-')
    }
    const modalRef = ref(null)
    let currentItem = null
    function editItem(item) {
      currentItem = item
      state.dataEdit.type = item.type
      state.dataEdit.label = item.label
      state.dataEdit.placeholder = item.placeholder
      state.updateData.label = item.label
      state.updateData.placeholder = item.placeholder
      state.updateData.options = item.options && item.options.length > 0 ? item.options : ['Pilihan 1'] || null
      state.updateData.min_value = item.min_value || null
      state.updateData.max_value = item.max_value || null
      state.updateData.required = item.required || false
      modalRef.value.show()
    }
    const updateText = val => {
      state.updateData.label = val
    }
    const updatePlaceholder = val => {
      state.updateData.placeholder = val
    }
    function cancelEdit() {
      modalRef.value.hide()
    }
    function saveEdit() {
      if (currentItem) {
        currentItem.label = state.updateData.label
        currentItem.placeholder = state.updateData.placeholder
        currentItem.options = state.updateData.options
        currentItem.min_value = state.updateData.min_value
        currentItem.max_value = state.updateData.max_value
        currentItem.required = state.updateData.required
      }
      modalRef.value.hide()
    }
    const addInput = () => {
      state.updateData.options.push(`Pilihan ${state.updateData.options.length + 1}`)
    }
    const maxNumberLength = (event, maxLength) => {
      const modifiedEvent = { ...event }
      if (modifiedEvent.value.length > maxLength) {
        modifiedEvent.value = modifiedEvent.value.slice(0, maxLength)
      }
    }

    const isEmpty = () => {
      if (!state.form.description.length) {
        error.form.description = 'Masukkan Closing Statement'
      } else {
        error.form.description = ''
      }

      if (!state.form.linkUrl.length) {
        error.form.linkUrl = 'Masukkan URL Setting'
      } else {
        error.form.linkUrl = ''
      }
    }

    watchEffect(() => {
      isEmpty()
      disabledButton()
    })
    onMounted(() => {})

    return {
      router,
      state,
      error,
      isNumber,
      handleBack,
      validateExt,
      validateSize,
      photoUpload,
      maxNumberLength,
      handleSubmitFormEvent,
      onMove,
      onMoveView,
      dataPersonal,
      dataBisnis,
      dataCustom,
      onDragEnd,
      onDragEndCustom,
      viewContent,
      dragItem,
      removeItem,
      replaceSpaceWithDash,
      modalRef,
      editItem,
      cancelEdit,
      saveEdit,
      updateText,
      updatePlaceholder,
      addInput,
      imagePersonal,
      imageBisnis,
      imageCustom,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.divider_line {
  border-top: 1px dashed #E2E2E2;
  margin: 18px 0;
}
.divider_solid {
  border-top: 1px solid #E2E2E2;
  margin: 18px 0;
}
#type-event {
  border-radius: 6px;
}
.before_preview {
  opacity: 0.3;
}
.preview_image {
  width: 100%;
  min-height: 200px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  background-color: #F8F8F8;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.preview_image .image_preview {
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.preview_image .show_image {
  width: -webkit-fill-available;
}

button.btn-upload {
  border: 1px solid #F95031 !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  padding: 4px 8px;
}
.input-group-append {
  border: 1px dashed #E2E2E2;
  padding: 8px 14px;
  margin: 5px;
  border-radius: 8px;
}
.scroll_event {
  height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 20px;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #828282;
  border-radius: 30px;
}
.nav-tabs {
  border-bottom: 1px solid #E2E2E2 !important;
}
.section_datadiri {
  padding: 20px;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #E2E2E2;
}
.content_datadiri {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 4px 4px 18px 0px #0000001F;
  border: 1px solid #E2E2E2;
  text-align: -webkit-center;
  cursor: pointer;
}
.content_datadiri_off {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  text-align: -webkit-center;
  cursor: not-allowed;
  opacity: 0.5;
}
.text_content_datadiri {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #FF6A3A;
}
.text_content_datadiri_off {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #C2C2C2;
}
.preview_content {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
}
.preview_content .no_content {
  padding: 18px;
  border-radius: 8px;
  border: 2px dashed #E2E2E2;
  text-align: center;
}
.preview_content .no_content span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #F95031;
}
.icon_more {
  transform: rotate(90deg);
  background-color: #F4F4F4;
  width: 30px;
  height: -webkit-fill-available;
  padding: 2px;
  border-radius: 8px;
}
.disabled-icon {
  opacity: 0.5;
  cursor: not-allowed;
}
.cursor-ponter {
  cursor: pointer;
}
.option-custom {
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  padding: 1rem;
}
</style>
