<template>
  <div>
    <b-modal
      id="modal-add-event"
      hide-footer
      hide-header
      :centered="true"
    >
      <div class="px-1 py-2">
        <div class="d-flex justify-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt=""
            srcset=""
          >

        </div>
        <div class="mb-2 mt-2">
          <h3 class="font-bold text-black text-center">
            Apakah kamu yakin data yang diinput sudah benar ?
          </h3>
        </div>
        <div>
          <div class="text-center">
            Kamu harus memastikan data ini sudah benar, setelah data ini tersubmit data tidak dapat dihapus nantinya
          </div>
        </div>
        <!-- <div
          class=" mt-2 d-flex justify-center"
        > -->
        <b-row class="mt-2">
          <b-col>
            <b-button
              block
              variant="outline-primary"
              @click="$bvModal.hide('modal-add-event')"
            >
              Kembali
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              block
              @click="$emit('submit') && $bvModal.hide('modal-add-event')"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>

        <!-- </div> -->
      </div>
    </b-modal>
  </div>
</template>
<script>

export default {
  props: {

  },
  setup() {

  },
}
</script>
