<template>
  <b-card>

    <div>
      <div class="text-black text-2xl font-semibold pb-2">
        Tambah Data Event
      </div>
    </div>
    <section class="section_stepper">
      <b-row>
        <b-col
          md="6"
          class="my-[5px]"
        >
          <div class="divider_icon">
            <div
              v-if="state.isDefault"
              class="circle circle_todo"
            >
              <span>1</span>
            </div>
            <img
              v-else
              src="https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg"
              width="32px"
              alt="icone_done"
            >
            <span :class="state.isDefault ? 'text_circle' : 'text_circle text_done'">Data Event</span>
          </div>
        </b-col>
        <b-col
          md="6"
          class="my-[5px]"
        >
          <div class="divider_icon2">
            <div :class="state.isNext ? 'circle circle_todo' : 'circle circle_letdo'">
              <span>2</span>
            </div>
            <span :class="state.isNext ? 'text_circle text_todo' : 'text_circle text_default'">Form Event</span>
          </div>
        </b-col>
      </b-row>
    </section>
    <div class="divider_solid" />
    <section v-if="state.isDefault">
      <div>
        <b-row>
          <b-col md="8">
            <section class="scroll_event">
              <div class="text-[#333333] text-lg mb-2">
                Informasi Event
              </div>
              <b-form-group
                id="title_event"
                label-cols="4"
                label-cols-lg="3"
                label="*Judul Event"
                label-class="text-md text-[#828282]"
                label-for="title_event"
              >
                <template #label>
                  <span class="text-red-500">*</span>Judul Event
                </template>
                <b-form-input
                  id="title_event"
                  v-model="state.form.event_title"
                  type="text"
                  :maxlength="50"
                  :minlength="3"
                  placeholder="Buat judul event"
                  :class="error.form.event_title?'border-primary': ''"
                  required
                />
                <p
                  v-if="error.form.event_title"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.event_title }}
                </p>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label-cols="4"
                label-cols-lg="3"
                label="*Sub-Judul Event"
                label-class="text-md text-[#828282]"
                label-for="sub_title_event"
              >
                <template #label>
                  <span class="text-red-500">*</span>Sub-Judul Event
                </template>
                <b-form-input
                  id="sub_title_event"
                  v-model="state.form.event_sub_title"
                  rows="3"
                  :maxlength="200"
                  placeholder="Buat sub-judul event"
                  :class="error.form.event_sub_title?'border-primary': ''"
                />
                <p
                  v-if="error.form.event_sub_title"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.event_sub_title }}
                </p>
              </b-form-group>
              <b-form-group
                id="textarea-rows"
                label-cols="4"
                label-cols-lg="3"
                label="*Deskripsi"
                label-class="text-md text-[#828282]"
                label-for="textarea-rows"
              >
                <template #label>
                  <span class="text-red-500">*</span>Deskripsi
                </template>
                <div>
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="state.form.description"
                    placeholder="Masukan Deskripsi Event"
                    rows="8"
                    :maxlength="700"
                    :class="error.form.description?'border-primary': ''"
                  />
                  <span class="text-xs flex justify-content-end"> {{ state.form.description.length }}/700</span>
                  <p
                    v-if="error.form.description"
                    class="text-xs  text-primary"
                  >
                    {{ error.form.description }}
                  </p>
                </div>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label-cols="4"
                label-cols-lg="3"
                label="*Tipe Event"
                label-class="text-7 text-[#828282]"
                label-for="type_event"
              >
                <template #label>
                  <span class="text-red-500">*</span>Tipe Event
                </template>
                <v-select
                  id="type-event"
                  v-model="state.form.event_type"
                  :options="state.options"
                  label="type_event"
                  placeholder="Pilih tipe event"
                  :class="error.form.event_type?'border-primary': ''"
                />
                <p
                  v-if="error.form.event_type"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.event_type }}
                </p>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label="*Diselenggarakan"
                label-cols="4"
                label-cols-lg="3"
                class="mt-[2rem]"
                label-class="flex items-center text-md text-[#828282]"
              >
                <template #label>
                  <span class="text-red-500">*</span>Diselenggarakan
                </template>
                <b-form-radio-group
                  v-model="state.form.event_status"
                  :options="state.radio"
                  style="accent-color: #F95031; margin-top: 0.6rem; margin-left: 0.6rem;"
                  name="radio-inline"
                  @change="statusEvent"
                />
              </b-form-group>
              <div class="divider_line" />
              <div class="text-[#333333] text-lg mb-2">
                Detail Event
              </div>
              <b-form-group
                id="fieldset-1"
                label="*Waktu"
                label-cols="4"
                label-cols-lg="3"
                label-class="text-md text-[#828282]"
                label-for="date"
              >
                <template #label>
                  <span class="text-red-500">*</span>Waktu
                </template>
                <date-range-picker
                  ref="picker"
                  v-model="state.dateRangeActivity"
                  :locale-data="state.locale"
                  :ranges="false"
                  :show-ranges="false"
                  class="w-[100%] mt-[1rem]"
                  :class="error.form.start_date?'border-primary': ''"
                >
                  <template
                    v-slot:input="picker"
                  >
                    <div class="d-flex justify-content-between align-items-center w-100">
                      <div
                        v-if="state.dateRangeActivity.startDate === null"
                      >
                        Pilih waktu event
                      </div>
                      <div v-else>
                        {{ DAY_MONTH_YEAR( picker.startDate ) }} - {{ DAY_MONTH_YEAR(picker.endDate ) }}
                      </div>
                      <div
                        class="ml-1"
                      >
                        <img
                          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                          alt="calendar"
                          srcset=""
                        >
                      </div>
                    </div>

                  </template>
                </date-range-picker>
                <p
                  v-if="error.form.event_start_date"
                  class="text-xs mt-[2px] text-primary"
                >
                  {{ error.form.event_start_date }}
                </p>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label="*Lokasi"
                label-cols="4"
                label-cols-lg="3"
                label-class="text-md text-[#828282]"
                label-for="location"
              >
                <template #label>
                  <span class="text-red-500">*</span>Lokasi
                </template>
                <b-form-input
                  id="location"
                  v-model="state.form.event_location"
                  :value="state.form.event_status === 'online' ? 'Via Zoom Meeting' : state.form.location"
                  type="text"
                  placeholder="Masukkan lokasi event"
                  required
                  :class="error.form.event_location?'border-primary': ''"
                />
                <p
                  v-if="error.form.event_location"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.event_location }}
                </p>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label-cols="4"
                label-cols-lg="3"
                label="*Jumlah Peserta"
                label-class="text-md text-[#828282]"
                label-for="max_participants"
                class="mt-[2rem]"
              >
                <template #label>
                  <span class="text-red-500">*</span>Jumlah Peserta
                </template>
                <b-form-input
                  id="max_participants"
                  v-model="state.form.max_attendance"
                  type="number"
                  placeholder="99"
                  :class="error.form.max_attendance?'border-primary': ''"
                  required
                  @input="maxNumberLength($event, 5)"
                  @keypress="isNumber($event)"
                />
                <p
                  v-if="error.form.max_attendance"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.max_attendance }}
                </p>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label="*Harga"
                label-cols="4"
                label-cols-lg="3"
                label-class="text-md text-[#828282]"
                label-for="price"
                :class="error.form.event_price?'mt-[0.6rem]': 'mt-[2.5rem]'"
              >
                <template #label>
                  <span class="text-red-500">*</span>Harga
                </template>
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text style="background-color: #F4F4F4; color:#626262">
                      Rp
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="price"
                    v-model="state.form.event_price"
                    type="text"
                    placeholder="0"
                    required
                    :class="error.form.event_price?'border-primary': ''"
                    @input="formatPrice"
                    @keypress="handleInput($event)"
                  />
                </b-input-group>
                <p
                  v-if="error.form.event_price"
                  class="text-xs mt-[4px] text-primary"
                >
                  {{ error.form.event_price }}
                </p>
              </b-form-group>
              <b-form-group
                id="link"
                label-cols="4"
                label-cols-lg="3"
                label="Link Landing Page"
                label-class="text-md text-[#828282]"
                label-for="link"
              >
                <b-form-input
                  id="link"
                  v-model="state.form.link_landingpage"
                  type="url"
                  placeholder="Masukkan Link Event"
                  required
                />
              </b-form-group>
              <div class="divider_line" />
              <div class="text-[#333333] text-lg mb-2">
                Narasumber
              </div>
              <b-row
                v-for="(speaker, index) in state.form.speakers"
                :key="index"
                class="items-center"
              >
                <b-col md="6">
                  <b-form-group
                    id="fieldset-1"
                    label=""
                    label-class="text-7 text-[#828282]"
                    label-for="type_event"
                    :class="!state.selectedSpeaker[index] ? 'border-primary form_speakers' : ''"
                  >
                    <v-select
                      id="type-event"
                      v-model="state.selectedSpeaker[index]"
                      :reduce="item => ({ name: item.name, pumkm_id: item.pumkm_id, user_id: item.user_id})"
                      :options="state.optionsSpeaker"
                      label="name"
                      taggable
                      :maxlength="10"
                      placeholder="Pilih narasumber"
                      @search="debounceSearch"
                    >
                      <template #option="{ name, pumkm_id }">
                        <div class="d-flex align-items-center">
                          <span class="mr-[5px]">{{ truncateText(name, 23) }}</span>
                          <img
                            v-if="pumkm_id !== 0 && isInList(name)"
                            src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-pumkm.svg"
                            alt="speaker-image"
                            width="18px"
                          >
                        </div>
                      </template>
                      <template #selected-option="{ name, pumkm_id }">
                        <div class="d-flex align-items-center">
                          <span class="mr-[5px]">{{ truncateText(name, 20) }}</span>
                          <img
                            v-if="pumkm_id !== 0 && isInList(name)"
                            src="https://storage.googleapis.com/komerce/assets/logo/logo-only/Logo-pumkm.svg"
                            alt="speaker-image"
                            width="18px"
                          >
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <section class="d-flex gap-[10px] items-center">
                    <b-form-group
                      id="fieldset-1"
                      label=""
                      label-class="text-7 text-[#828282]"
                      label-for="type_event"
                      :class="!state.selectedPosition[index] ? 'border-primary form_speakers w-100' : 'w-100'"
                    >
                      <v-select
                        id="type-event"
                        v-model="state.selectedPosition[index]"
                        :options="state.optionsPosision"
                        label="type_event"
                        placeholder="Pilih Posisi"
                      />
                    </b-form-group>
                    <div
                      v-if="state.form.speakers.length > 1"
                      class="button_trash"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                        alt="trash"
                        class="form-group"
                        @click="removeSpeaker(index)"
                      >
                    </div>
                  </section>
                </b-col>
                <b-col md="12">
                  <p
                    v-if="!state.selectedSpeaker[index] || !state.selectedPosition[index]"
                    class="text-xs text-primary"
                  >
                    Masukkan Narasumber dan Posisi
                  </p>
                </b-col>
              </b-row>
              <div
                v-if="state.form.speakers.length < 10"
                class="button_add_row mb-[15px]"
              >
                <b-button
                  variant="outline-primary"
                  class="d-flex items-center gap-2"
                  @click="addSpeaker"
                >
                  <span>+</span>
                  <span>Tambah narasumber</span>
                </b-button>
              </div>
            </section>
          </b-col>
          <b-col md="4">
            <div class="text-[#333333] text-lg mb-2">
              Unggah Poster
            </div>
            <div class="preview_image mb-2">
              <section :class="state.previewImage ? 'show_image' : ''">
                <span
                  v-if="!state.previewImage"
                  class="before_preview"
                >
                  <img src="https://storage.googleapis.com/komerce/assets/illustration/Support-sending-images-&-text.svg">
                </span>
                <img
                  v-if="state.previewImage"
                  :src="state.previewImage"
                  :class="state.previewImage ? 'image_preview' : 'image_not_preview'"
                  :data-target="state.previewImage ? '#exampleModal' : ''"
                  :data-toggle="state.previewImage ? 'modal' : ''"
                  alt="Preview Image"
                >
              </section>
            </div>
            <div
              id="exampleModal"
              class="modal fade"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <img
                      :src="state.previewImage"
                      alt="Preview Image"
                    >
                  </div>
                  <div class="modal-footer justify-content-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <b-input-group>
              <b-input-group-append
                class="d-flex align-items-center w-100"
                @click="$refs.photoFile.click()"
              >
                <BButton
                  class="d-flex align-items-center ml-[4px] w-40 btn-upload"
                  size="sm"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-upload.svg"
                    alt=""
                    width="15px"
                  >
                  <span
                    class="ml-[2px] text-[#F95031]"
                    style="font-size: 12px"
                  >
                    Pilih Foto</span>
                </BButton>
                <b-form-input
                  v-model="state.photoName"
                  class="text-file-foto"
                  style="border-left: none;
                        border-bottom-left-radius: 0%;
                        border-top-left-radius: 0%;
                        border: none;
                        background-color: white;"
                  :readonly="true"
                  :placeholder="state.photoName? state.photoName : 'upload Foto' "
                />
              </b-input-group-append>
              <input
                id="poster"
                ref="photoFile"
                class="form-control"
                type="file"
                style="display: none"
                @change="validateExt"
              >
            </b-input-group>
            <div
              :class="error.form.poster?'text-primary': ''"
              class="text-xs mt-[4px]"
            >
              *Upload foto dengan maksimal 1 Mb ukuran 300 x 300px
            </div>
          </b-col>
        </b-row>
        <div class="divider_solid" />
        <b-row>
          <b-col>
            <div
              class="d-flex mt-2 md:mt-2 justify-end "
              style="gap: 1rem;"
            >
              <b-button
                variant="outline-primary"
                :disabled="state.isNext === true"
                @click="handleBack"
              >
                Kembali
              </b-button>
              <b-button
                v-b-modal.modal-add-event
                :variant="state.isDisabled === true ? 'secondary' : 'primary'"
                :disabled="state.isDisabled === true"
                @click="handleSubmit"
              >
                Simpan
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
    <section v-if="state.isNext">
      <FormEvent :data="state.idEvent" />
    </section>
    <div>
      <modal-add-event
        @submit="handleConfirmSubmit"
      />
    </div>
  </b-card>

</template>

<script>
import {
  reactive, ref, watchEffect, onMounted,
} from '@vue/composition-api'
import { newAxiosIns, affiliateAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import router from '@/router'
import dateRangePicker from 'vue2-daterange-picker'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import { YEAR_MONTH_DAY, DAY_MONTH_YEAR } from '@/libs/filterDate'
import { isNumber } from '@/libs/helpers'
import { toastSuccess, toastError } from '../config'
import modalAddEvent from './modalAddEvent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import FormEvent from './formEvent.vue'

export default {
  components: {
    vSelect,
    dateRangePicker,
    modalAddEvent,
    FormEvent,
  },

  setup() {
    const picker = ref({
      startDate: firstDateOfMonth,
      endDate: lastDateOfMonth,
    })
    const error = reactive({
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
    })
    const state = reactive({
      photoName: '',
      originalPoster: null,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      dateRangeUpdate: {
        startDate: today,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: null,
        endDate: null,
      },
      data: {},
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
        speakers: [
          {
            user_id: 0,
            pumkm_id: 0,
            name: null,
            position: null,
          },
        ],
      },
      previewImage: null,
      radio: [
        { text: 'Online', value: 'online' },
        { text: 'Offline', value: 'offline' },
      ],
      options:
      ['Bootcamp', 'Gathering', 'Kopdar Komunitas', 'Webinar', 'Roadshow', 'Seminar', 'Workshop'],
      optionsPosision: ['Speaker', 'Trainer', 'Fasilitator'],
      optionsSpeaker: [],
      selectedSpeaker: [],
      selectedPosition: [],
      search: '',
      resultSearch: false,
      debounceSearch: null,
      isDisabled: false,
      isDefault: true,
      isNext: false,
      idEvent: 0,
    })

    const photoUpload = ref({})

    function statusEvent() {
      if (state.form.status_event === 'online') {
        state.form.location = 'Via Zoom Meeting'
      } else {
        state.form.location = ''
      }
    }
    const disabledButton = () => {
      const selectedData = state.selectedSpeaker.map((speaker, index) => ({
        name: speaker.name,
        position: state.selectedPosition[index],
      }))
      const dataSelected = selectedData.some(i => i.name && i.position)
      if (
        !state.form.event_type
        || state.form.event_title.length === ''
        || state.form.event_title.length <= 3
        || state.form.event_sub_title.length === ''
        || state.form.event_sub_title === ''
        || !state.photoName
        || state.form.max_attendance === ''
        || state.form.max_attendance === '0'
        || state.form.max_attendance.length >= 6
        || state.form.event_price === ''
        || state.form.event_price.length <= 3
        || state.form.event_location.length >= 256
        || state.form.event_location === ''
        || state.form.event_status === ''
        || state.dateRangeActivity.startDate === null
        || state.dateRangeActivity.endDate === null
        || state.form.event_sub_title === ''
        || state.form.description === ''
        || dataSelected === false
      ) {
        state.isDisabled = true
      } else {
        state.isDisabled = false
      }
    }
    function handleSubmit() {
      const selectedData = state.selectedSpeaker.map((speaker, index) => ({
        user_id: speaker.user_id,
        pumkm_id: speaker.pumkm_id,
        name: speaker.name,
        position: state.selectedPosition[index],
      }))
      const price = state.form.event_price.split('.').join('')
      const formData = new FormData()
      formData.append('event_type', state.form.event_type)
      formData.append('event_title', state.form.event_title)
      formData.append('event_sub_title', state.form.event_sub_title)
      formData.append('event_status', state.form.event_status)
      formData.append('event_price', parseInt(price, 10))
      formData.append('event_location', state.form.event_location)
      formData.append('max_attendance', parseInt(state.form.max_attendance, 10))
      formData.append('event_start_date', YEAR_MONTH_DAY(state.dateRangeActivity.startDate))
      formData.append('event_end_date', YEAR_MONTH_DAY(state.dateRangeActivity.endDate))
      formData.append('poster', photoUpload.value)
      formData.append('description', state.form.description)
      formData.append('link_landingpage', state.form.link_landingpage)
      formData.append('event_performers', JSON.stringify(selectedData))

      state.data = formData
    }

    function handleConfirmSubmit() {
      const url = '/komclass/api/v2/event'
      newAxiosIns.post(url, state.data)
        .then(res => {
          const { data } = res.data
          state.idEvent = data.event_id
          toastSuccess('Berhasil menambahkan event')
          state.isDefault = false
          state.isNext = true
        })
        .catch(err => {
          toastError('Ada kesalahan saat menambah event')
        })
    }

    function handleBack() {
      router.back()
    }

    function addSpeaker() {
      state.form.speakers.push({
        user_id: 0,
        pumkm_id: 0,
        name: null,
        position: null,
      })
    }
    function removeSpeaker(index) {
      state.form.speakers.splice(index, 1)
    }
    function truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text
      }
      return `${text.slice(0, maxLength)}...`
    }
    function isInList(fullName) {
      return state.optionsSpeaker.some(option => option.name === fullName)
    }
    function getDataSpeaker() {
      const url = '/komclass/api/v1/event/possible-performers'
      const params = {
        search: state.search,
      }
      newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          state.optionsSpeaker = data
        })
        .catch(err => {
          toastError('Ada kesalahan saat menampilkan data')
        })
    }
    function handleEmptySearch() {
      if (state.search === '') {
        state.resultSearch = false
      }
    }

    async function handleSearch(value) {
      if (!value.length) {
        state.search = ''
        getDataSpeaker()
      } else if (value.length > 2) {
        state.search = value
        getDataSpeaker()
      }
    }
    function debounceSearch(search) {
      if (!search) {
        state.resultSearch = false
      }
      if (state.debounceSearch) {
        clearTimeout(state.debounceSearch)
      }
      state.debounceSearch = setTimeout(() => {
        handleSearch(search)
      }, 1000)
    }

    const validateSize = evChange => {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]
      state.previewImage = URL.createObjectURL(file)

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size >= maxSize) {
          toastError('Ukuran file terlalu besar. Maksimal 1 MB.')
          photoUpload.value = ''
          state.photoName = ''
          state.previewImage = state.originalPoster
        } else {
          photoUpload.value = file
        }
      }
    }
    const validateExt = evChange => {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (!file) {
        return
      }
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
      ) {
        toastError('Hanya dapat upload file dengan ekstensi JPG dan PNG')
        state.photoName = ''
        state.previewImage = state.originalPoster
      } else {
        state.photoName = evChange.target.files[0].name
        validateSize(evChange)
      }
    }

    const maxNumberLength = (event, maxLength) => {
      const modifiedEvent = { ...event }
      if (modifiedEvent.value.length > maxLength) {
        modifiedEvent.value = modifiedEvent.value.slice(0, maxLength)
      }
    }

    const handleInput = event => {
      const allowedChars = /[0-9\b]/

      if (allowedChars.test(event.key)) {
        const inputValue = event.target.value.replace(/[^\d]/g, '') // Hapus karakter selain digit
        state.form.event_price = inputValue
      } else {
        event.preventDefault()
      }
    }

    const isEmpty = () => {
      if (!state.form.event_type) {
        error.form.event_type = 'Masukkan Tipe Event'
      } else {
        error.form.event_type = ''
      }

      if (!state.form.event_title.length) {
        error.form.event_title = 'Masukkan Judul Event'
      } else {
        error.form.event_title = ''
      }

      if (!state.form.event_sub_title.length) {
        error.form.event_sub_title = 'Masukkan Sub-Judul Event'
      } else {
        error.form.event_sub_title = ''
      }

      if (!state.form.event_price.length) {
        error.form.event_price = 'Masukkan Harga Event'
      } else {
        error.form.event_price = ''
      }

      if (!state.form.event_location.length) {
        error.form.event_location = 'Masukkan Lokasi Event'
      } else {
        error.form.event_location = ''
      }

      if (!state.form.max_attendance.length) {
        error.form.max_attendance = 'Masukkan Jumlah Peserta'
      } else {
        error.form.max_attendance = ''
      }

      if (!state.form.description.length) {
        error.form.description = 'Masukkan Deskripsi Event'
      } else {
        error.form.description = ''
      }

      if (state.dateRangeActivity.startDate === null) {
        error.form.event_start_date = 'Masukkan Waktu Event'
      } else {
        error.form.event_start_date = ''
      }

      if (!state.photoName) {
        error.form.poster = 'Masukkan Waktu Event'
      } else {
        error.form.poster = ''
      }
    }

    const formatPrice = () => {
      const inputValue = state.form.event_price
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      state.form.event_price = formattedValue
    }

    watchEffect(() => {
      isEmpty()
      disabledButton()
    })
    onMounted(() => {
      getDataSpeaker()
      handleEmptySearch()
    })

    return {
      picker,
      router,
      state,
      error,
      isNumber,
      handleSubmit,
      statusEvent,
      handleConfirmSubmit,
      handleBack,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      validateExt,
      validateSize,
      photoUpload,
      maxNumberLength,
      formatPrice,
      handleInput,
      addSpeaker,
      removeSpeaker,
      isInList,
      debounceSearch,
      handleSearch,
      truncateText,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.divider_line {
  border-top: 1px dashed #E2E2E2;
  margin: 18px 0;
}
.divider_solid {
  border-top: 1px solid #E2E2E2;
  margin: 18px 0;
}
#type-event {
  border-radius: 6px;
}
.before_preview {
  opacity: 0.3;
}
.preview_image {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  background-color: #F8F8F8;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.preview_image .image_preview {
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  height: 300px;
  object-fit: cover;
  object-position: center;
}
.preview_image .show_image {
  width: -webkit-fill-available;
}

.preview_image .image_preview:hover {
  opacity: 0.7;
}

button.btn-upload {
  border: 1px solid #F95031 !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
  padding: 4px 8px;
}
.input-group-append {
  border: 1px dashed #E2E2E2;
  padding: 8px 14px;
  border-radius: 8px;
}
.scroll_event {
  height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 20px;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #828282;
  border-radius: 30px;
}
.form_speakers {
  border-radius: 5px;
}
.button_trash {
  cursor: pointer;
}

.section_stepper {
  padding: 24px;
  background-color: #F8F8F8;
  border-radius: 4px
}

.divider_icon, .divider_icon2 {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
}

/* .divider_icon::before, */
.divider_icon::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #E2E2E2;
}

.divider_icon .circle,
.divider_icon2 .circle {
  width: 28px;
  height: 28px;
  padding: 8px;
  gap: 8px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider_icon .circle,
.divider_icon2 .circle {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
}
.divider_icon .circle.circle_todo,
.divider_icon2 .circle.circle_todo {
  background-color: #F95031;
}
 .divider_icon .circle.circle_done {
  background-color: #34A770;
}
.divider_icon2 .circle.circle_letdo {
  background-color: #828282;
}
.divider_icon .text_circle.text_done {
  color: #333333;
}
.divider_icon .text_circle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #F95031;
}
.divider_icon2 .text_circle.text_default {
color: #828282;
font-size: 14px;
font-weight: 400;
line-height: 20px;
}
.divider_icon2 .text_circle.text_todo {
color: #F95031;
font-size: 14px;
font-weight: 600;
line-height: 20px;
}
</style>
